.console {
  & pre {
    margin-bottom: 2px !important;
  }
}

.warn {
  color: var(--sp-colors-warning);
  background-color: var(--sp-colors-warningSurface);
}

.error {
  color: var(--sp-colors-error);
  background-color: var(--sp-colors-errorSurface);
}
