.button {
  border: 1px solid;
  border-color: var(--sp-colors-surface2);
  background-color: var(--sp-colors-surface1);
  width: var(--sp-space-7);
  height: var(--sp-space-7);
  &:hover {
    border-color: var(--sp-colors-clickable);
  }
}

.button.enabled {
  border-color: var(--sp-colors-clickable);
  background-color: var(--sp-colors-surface3);

  &:hover {
    border-color: var(--sp-colors-active);
    background-color: var(--sp-colors-surface3);
  }
}

.button.hidden {
  opacity: 0.5;
}
