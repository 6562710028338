.ProgressRoot {
  position: relative;
  overflow: hidden;
  background: var(--color-gray4);
  border-radius: 99999px;
  flex-basis: 100%;
  height: 12px;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}

.ProgressIndicator {
  background-image: linear-gradient(90deg, var(--color-sky9) 0%, #9747ff 100%);
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}

.SuccessProgressIndicator {
  background-image: linear-gradient(
    90deg,
    hsl(0deg 96% 45%) 0%,
    hsl(27deg 100% 47%) 24%,
    hsl(40deg 100% 49%) 35%,
    hsl(52deg 100% 50%) 45%,
    hsl(64deg 100% 49%) 55%,
    hsl(75deg 100% 49%) 65%,
    hsl(88deg 100% 50%) 76%,
    hsl(112deg 100% 50%) 100%
  );
}
