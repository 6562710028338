/**
const headerCss = css({
  background: 'var(--sp-colors-surface1)',
  border: '1px solid var(--sp-colors-surface2)',
  borderBottom: 'none',
  borderRadius: 'var(--sp-border-radius) var(--sp-border-radius) 0 0',
  padding: 8,
  display: 'flex',
});
*/

.typography {
  color: var(--sp-colors-accent);
}

.editor {
  border: 1px solid var(--sp-colors-surface2) !important;
  border-top: none !important;
}

.main *::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: var(--sp-colors-surface1);
}

.main *::-webkit-scrollbar-thumb {
  background: var(--sp-colors-clickable);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.main *::-webkit-scrollbar-corner {
  background: #000;
}
